import { Controller  } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from '@fullcalendar/core/locales/es';

export default class extends Controller {
    static targets = [ "calendar" ]

    connect(){
        let calendar = new Calendar(this.calendarTarget, {
            events: '/mantenimientos/calendario.json',
            editable: false,
            navLinks: true,
            locale: esLocale,
            headerToolbar: { center: 'dayGridMonth, timeGridWeek, timeGridDay' },
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            eventClick: function (info){
                info.jsEvent.preventDefault()
                window.location.assign(info.event.extendedProps.show_url)
            },
        })
        calendar.render()
    }
}