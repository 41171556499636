import { Controller } from 'stimulus'

export default class extends Controller{
    static targets = ["select", "choice", "long"]

    connect() {
        this.selected()
    }

    selected(){
        this.hideFields()
        switch (this.selectTarget.value) {
            case 'unica_opcion':
                this.choiceTarget.classList.remove('hidden')
                break;
            case 'multiple_opcion':
                this.choiceTarget.classList.remove('hidden')
                break;
            case 'respuesta_larga':
                this.choiceTarget.classList.remove('hidden')
                break;
        }
    }

    hideFields(){
        this.choiceTarget.classList.add('hidden')
        this.longTarget.classList.add('hidden')
    }

}